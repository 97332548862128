<template>
  <div v-if="$store.getters['character/test_is_over']" class="mt-10">
    <h1 class="text-center">{{ $t('chooseTest.attention') }}</h1>
    <p class="mt-5 text-center">{{ $t('chooseTest.warning') }}</p>
    <div class="flex justify-center mt-5 mb-8">
      <button class="button-md py-4 px-4 bg-white text-blue rounded-xl border-blue mr-8" @click="$router.push('/home')">
        {{ $t('userEdit.cancel') }}
      </button>

      <button class="button-md py-4 px-4 bg-blue text-white rounded-xl" @click="reset_test">
        {{ $t('chooseTest.proceed') }}
      </button>
    </div>
  </div>
  <div v-else class="mt-5">
    <h1 class="text-center">{{ $t('chooseTest.title') }}</h1>
    <ul class="mx-auto mt-5 list-disc px-2" style="max-width: 600px">
      <li class="ml-3">{{ $t('chooseTest.subtitle.explanation') }}</li>
      <li class="ml-3">{{ $t('chooseTest.subtitle.purpose') }}</li>
      <li class="ml-3">{{ $t('chooseTest.subtitle.accuracy') }}</li>
    </ul>
    <div class="flex flex-col justify-center items-center mt-7 text-white font-bold">
      <div v-for="cardSet in cardSets" :key="cardSet" class="flex flex-col md:flex-row mb-4">
        <div v-for="card in cardSet.cards" :key="card"
             :class="cardSet.colors.card"
             class="selection-box mr-0 md:mr-4 flex items-center justify-around relative cursor-pointer my-2 md:my-0"
             @click="onCardClick(card.questions)">
          <div v-if="card.questions > 30 && !$store.getters['account/has_premium']"
               class="absolute inset-x-0 inset-y-0 flex justify-center items-center text-3xl cursor-pointer">
            <div class="absolute inset-x-0 inset-y-0 opacity-50 bg-black rounded-xl z-40">
            </div>
            <i class="fas fa-lock z-50"></i>
          </div>
          <div :class="cardSet.colors.circle" class="absolute circle"></div>
          <div class="absolute grid grid-cols-4 gap-4 dot-set">
            <div v-for="i in 4*4" :key="i" :class="cardSet.colors.dot" class="dot"></div>
          </div>
          <div class="flex flex-col z-10">
            <div class="text-xl">{{ card.questions }} {{ $t('chooseTest.questions') }}</div>
            <div class="italic text-sm font-normal">{{ card.accuracy }}% {{ $t('chooseTest.accuracy') }}</div>
          </div>
          <div :class="cardSet.colors.ring"
               class="flex items-center justify-center flex-col border-circle border-4">
            <div>{{ card.minutes }}</div>
            <div>min</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GetPremium v-if="$store.getters['common/showPremiumModal']"></GetPremium>
</template>

<style>
.button-md {
  width: 150px;
}

.dot {
  height: 4px;
  width: 4px;
  border-radius: 50%;
}

.dot-set {
  transform: translate(-50%, 0);
  left: 55%;
  bottom: 10px;
}

.border-circle {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.border-darkbrown {
  border-color: rgb(170, 144, 125);
}

.border-lightblue {
  border-color: rgb(121, 143, 196);
}

.circle {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  top: 2px;
  left: 8px;
}

.bg-darkbrown {
  background-color: rgb(170, 144, 124);
}

.bg-lightblue {
  background-color: rgb(121, 143, 196);
}

.selection-box {
  width: 300px;
  height: 150px;
  @apply rounded-xl;
}

.bg-brown {
  background-color: rgb(189, 157, 135);
}

.bg-blue {
  background-color: rgb(113 134 187);
}
</style>

<script>
import axios from "../axios";
import GetPremium from "@/components/GetPremium";

export default {
  components: {
    GetPremium
  },
  data() {
    return {
      cardSets: [
        {
          colors: {
            card: 'bg-brown', circle: 'bg-darkbrown', dot: 'bg-darkbrown', ring: 'border-darkbrown'
          },
          cards: [
            {questions: 30, accuracy: 12, minutes: 3.5},
            {questions: 60, accuracy: 25, minutes: 7}
          ]
        },
        {
          colors: {
            card: 'bg-blue', circle: 'bg-lightblue', dot: 'bg-lightblue', ring: 'border-lightblue'
          },
          cards: [
            {questions: 120, accuracy: 50, minutes: 14},
            {questions: 240, accuracy: 100, minutes: 28}
          ]
        }
      ]
    };
  },
  methods: {
    onCardClick(questions) {
      if(questions > 30 && !this.$store.getters['account/has_premium']) {
        this.$store.commit('common/setShowPremiumModal', { status: true });
        return;
      }
      this.set_question_limit(questions);
    },
    async set_question_limit(question_limit) {
      const response = await axios.post("/character/", {
        question_limit: question_limit,
        locale: this.$store.getters['account/selected_locale']
      });
      const character = response.data;
      //Set traits to null, so that getters['character/traits'] in Dashboard.vue returns null
      //and causes the character to be loaded again.
      character.traits = null;
      this.$store.commit("character/set", {character});
      this.$router.push("/test");
    },
    async reset_test() {
      await axios.delete("/character");
      this.$store.commit("character/delete");
      this.$store.commit("activity/delete_scores");
      document.getElementById('mycharacter').classList.add('bg-white');
    },
  }
};
</script>
